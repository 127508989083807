import React from 'react';

import { IconProps } from '../IconRoot';

export const CallCircle: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-4.812 3.577a2.47 2.47 0 00.075-.527.418.418 0 00-.022-.157c-.03-.09-.22-.222-.569-.396-.094-.055-.229-.13-.403-.224a39.066 39.066 0 01-.475-.262c-.142-.08-.275-.157-.4-.232a8.75 8.75 0 00-.187-.13 2.08 2.08 0 00-.265-.161.487.487 0 00-.213-.053c-.1 0-.224.071-.374.213-.15.142-.287.297-.411.464a4.488 4.488 0 01-.397.463c-.139.142-.254.214-.343.214a.506.506 0 01-.169-.038 1.606 1.606 0 01-.153-.063 2.724 2.724 0 01-.18-.105 4.18 4.18 0 00-.141-.086 7.85 7.85 0 01-1.757-1.3 7.85 7.85 0 01-1.301-1.758 4.32 4.32 0 00-.086-.142 2.713 2.713 0 01-.105-.18 1.642 1.642 0 01-.063-.153.505.505 0 01-.038-.168c0-.09.071-.204.213-.344.143-.14.297-.271.464-.396.167-.124.322-.262.463-.411.143-.15.214-.274.214-.374 0-.07-.018-.14-.053-.213a2.088 2.088 0 00-.16-.265 9.402 9.402 0 00-.131-.187 16.019 16.019 0 01-.232-.4c-.08-.142-.167-.3-.262-.475-.094-.174-.17-.309-.224-.404-.174-.349-.307-.538-.396-.568a.419.419 0 00-.157-.022c-.135 0-.31.025-.527.074a2.97 2.97 0 00-.512.157c-.25.105-.514.41-.793.913-.254.468-.381.932-.381 1.39a2.88 2.88 0 00.12.822c.044.16.08.278.108.356.027.077.078.215.153.415.075.199.12.321.135.366.174.488.381.924.62 1.308.394.638.931 1.297 1.611 1.978.68.68 1.34 1.217 1.978 1.61.383.24.82.447 1.308.621.045.015.167.06.366.135.2.074.338.126.415.153.078.027.196.064.355.108.16.045.303.076.43.094.127.017.258.026.393.026.458 0 .922-.127 1.39-.381.504-.28.808-.544.912-.793.055-.124.108-.295.157-.512z"
            fill="currentColor"
        />
    </svg>
);
