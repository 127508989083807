import React from 'react';

import { IconProps } from '../IconRoot';

export const Help: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-.058-5.666h-.803a.246.246 0 01-.266-.253 8.3 8.3 0 01.163-2.059c.081-.333.252-.637.495-.88.167-.178.346-.34.526-.505l.169-.155c.237-.22.521-.462.79-.682.528-.44.464-1.206-.196-1.566a1.54 1.54 0 00-2.156.759 3.012 3.012 0 00-.132.413c-.053.192-.14.26-.339.236l-1.569-.19a.277.277 0 01-.26-.334 3.214 3.214 0 012.663-2.838 4.56 4.56 0 012.77.255 2.99 2.99 0 011.683 1.76 2.327 2.327 0 01-.366 2.323 8.339 8.339 0 01-1.267 1.24l-.18.16c-.085.078-.17.155-.26.228a.994.994 0 00-.363.803c0 .21-.008.42-.017.63-.005.126-.01.252-.013.378a.27.27 0 01-.297.297h-.775v-.02zm.947 3.007a1.1 1.1 0 10-1.829-1.222 1.1 1.1 0 001.829 1.222z"
            fill="currentColor"
        />
    </svg>
);
