import React from 'react';

import { IconProps } from '../IconRoot';

export const Salute: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5 14.921c-.52-.857-2.73-.824-4.876-.791h-3.137c-5.38-.445-7.655-2.589-8.614-4.237a6.176 6.176 0 01-.244-5.573 8.317 8.317 0 012.268-2.775A8.174 8.174 0 0112.107 0a11.797 11.797 0 00-5.803 1.5 12.37 12.37 0 00-2.52 1.93 8.267 8.267 0 00-1.88 3.163 8.357 8.357 0 00-.33 3.679 8.856 8.856 0 001.493 3.617 8.704 8.704 0 002.896 2.599c3.418 2.136 7.546 1.996 10.454 1.897l.029-.001h1.625c1.317 0 2.243.148 2.552.791.076.236.076.49 0 .726 0 .115.114.148.211 0a11.927 11.927 0 002.601-4.947l.065-.033zm-7.016 8.07l-.002-.008.018-.008-.016.017zm-.002-.008c-.124-.642-1.605-.778-3.466-.93a25.619 25.619 0 01-4.14-.633 11.46 11.46 0 01-4.909-2.566 12.516 12.516 0 01-3.291-4.662C.5 13.902.5 13.953.5 14.261a12.716 12.716 0 002.452 5.36A11.626 11.626 0 007.47 23.13a10.28 10.28 0 004.22.844c.17.017.34.027.511.027a11.069 11.069 0 004.282-1.017zM9.612 6.937a4.45 4.45 0 01.035-2.125V4.78a5.128 5.128 0 011.226-2.039 5.193 5.193 0 012.02-1.276A7.491 7.491 0 0115.569 1h.285c.233.006.461.063.67.166.843.432 1.654.925 2.425 1.475a7.918 7.918 0 00-2.225.232c-.39.128-.745.344-1.036.632-.29.288-.509.64-.637 1.026a1.807 1.807 0 00.401 1.657 6.129 6.129 0 005.571 1.044 3.15 3.15 0 001.29-.812c.54.973.939 2.017 1.187 3.1a4.743 4.743 0 01-2.476 1.988 10.13 10.13 0 01-5.622.178 10.057 10.057 0 01-4.835-2.846 4.475 4.475 0 01-.955-1.903z"
            fill="currentColor"
            fillOpacity={0.96}
        />
    </svg>
);
