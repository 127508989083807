import React from 'react';

import { IconProps } from '../IconRoot';

export const CallEndCircle: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#ic_24_call_end_circle_svg__clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.929 19.071c-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0zm.873-5.932c.118.189.225.33.32.426a.42.42 0 00.127.095c.084.042.312.002.682-.122.105-.028.253-.07.444-.127.19-.056.364-.106.52-.15.157-.044.306-.084.447-.12.025-.003.1-.016.225-.039s.225-.047.301-.074a.488.488 0 00.188-.113c.07-.07.108-.21.113-.415a3.643 3.643 0 00-.037-.619 4.505 4.505 0 01-.047-.608c-.002-.199.029-.33.092-.394a.504.504 0 01.146-.092c.065-.03.116-.051.153-.064.037-.012.104-.03.2-.053.098-.022.152-.036.162-.04A7.846 7.846 0 0112 10.309c.691 0 1.412.108 2.162.323l.162.04c.097.022.164.04.2.052.038.013.089.034.154.064s.113.06.145.092c.064.064.094.195.093.394-.002.2-.018.402-.048.608-.03.206-.042.412-.037.619.005.206.043.344.114.415.05.049.112.087.187.113.076.027.177.051.302.074l.224.04c.141.035.29.075.447.119.157.044.33.094.52.15.191.057.34.1.445.127.37.124.597.164.682.122a.42.42 0 00.127-.095c.095-.095.201-.237.32-.426a2.96 2.96 0 00.25-.473c.103-.25.075-.652-.084-1.205-.151-.511-.39-.929-.714-1.253a2.892 2.892 0 00-.666-.497 6.094 6.094 0 00-.328-.174 29.429 29.429 0 00-.401-.185 15.75 15.75 0 01-.354-.164 6.774 6.774 0 00-1.364-.487c-.73-.172-1.576-.259-2.538-.259-.962 0-1.808.087-2.537.26-.44.101-.895.264-1.364.486l-.354.164c-.194.088-.328.15-.402.185a6.103 6.103 0 00-.328.174 2.89 2.89 0 00-.666.497c-.324.324-.562.742-.714 1.253-.158.553-.186.955-.084 1.205.05.127.133.285.25.473z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="ic_24_call_end_circle_svg__clip0">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
