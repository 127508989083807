import React from 'react';

import { IconProps } from '../IconRoot';

export const Location: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            opacity={0.95}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.546 18.258c1.701-2.425 3.12-5.56 3.12-8.258a8.667 8.667 0 00-17.333 0c0 2.697 1.42 5.833 3.121 8.258.858 1.223 1.813 2.3 2.742 3.08.51.428.975.736 1.394.947.352.177.86.382 1.41.382.55 0 1.058-.206 1.41-.382.419-.211.884-.519 1.393-.946.93-.78 1.884-1.858 2.743-3.081zm-1.637-1.149c1.596-2.275 2.758-5.004 2.758-7.11a6.667 6.667 0 00-13.334 0c0 2.106 1.162 4.835 2.758 7.11.784 1.117 1.625 2.055 2.391 2.698.83.696 1.349.86 1.518.86.17 0 .689-.165 1.518-.86.766-.643 1.607-1.581 2.39-2.698zm-3.91-2.776A4.667 4.667 0 1012 5a4.667 4.667 0 000 9.333zm0-2A2.667 2.667 0 1012 7a2.667 2.667 0 000 5.333z"
            fill="currentColor"
        />
    </svg>
);
