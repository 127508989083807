import React from 'react';

import { IconProps } from '../IconRoot';

export const Feedback: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.07 4.928C15.23 1.09 9.044 1.025 5.126 4.736l-.197.192c-3.905 3.905-3.905 10.237 0 14.142l.193.188c3.127 2.953 8.054 3.564 11.836 1.594l.137-.075 3.824 1.185.089.022a.834.834 0 00.966-1.02l-1.172-4.692.093-.184c1.845-3.784 1.199-8.135-1.825-11.16zM6.916 8.651a.74.74 0 01.421-.105c.295 0 .59.147.758.442.337.61 1.347.61 1.705-.021a.877.877 0 011.179-.316c.4.232.547.758.315 1.179a2.687 2.687 0 01-2.357 1.347 2.673 2.673 0 01-2.336-1.368.857.857 0 01.315-1.158zm10.186.017a.877.877 0 00-1.179.315c-.357.632-1.368.632-1.684 0a.865.865 0 00-.757-.442.74.74 0 00-.421.106c-.421.252-.568.757-.337 1.178a2.673 2.673 0 002.336 1.368c.99 0 1.873-.505 2.358-1.347a.877.877 0 00-.316-1.178zm-8.713 7.565c-.063.337.21.653.569.653h6.103c.358 0 .632-.337.569-.653a3.685 3.685 0 00-3.62-3.01 3.685 3.685 0 00-3.62 3.01z"
            fill="currentColor"
            fillOpacity={0.96}
        />
    </svg>
);
