import React from 'react';

import { IconProps } from '../IconRoot';

export const BankCardAlt1: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.67 6h8.66c1.304 0 2.104.003 2.689.074.534.064.623.159.624.16.045.038.085.078.122.123.002.001.097.09.161.624.058.478.07 1.098.073 2.019H4.001c.002-.921.015-1.541.073-2.019.064-.534.159-.623.16-.624a1 1 0 01.123-.122c.001-.002.09-.097.624-.161C5.566 6.004 6.366 6 7.67 6zM4 11v3.33c0 1.304.003 2.104.074 2.689.064.534.159.623.16.624.038.045.078.085.123.122.001.002.09.097.624.161.585.07 1.385.074 2.689.074h8.66c1.304 0 2.104-.003 2.689-.074.539-.064.624-.16.625-.16.044-.038.084-.078.122-.122.001-.002.096-.09.16-.625.07-.585.074-1.385.074-2.689V11H4zM2 9.67c0-2.509 0-3.763.704-4.6a3 3 0 01.366-.366C3.907 4 5.16 4 7.67 4h8.66c2.509 0 3.763 0 4.6.704.133.11.255.233.366.366C22 5.907 22 7.16 22 9.67v4.66c0 2.509 0 3.763-.704 4.6a2.998 2.998 0 01-.366.366c-.837.704-2.091.704-4.6.704H7.67c-2.509 0-3.763 0-4.6-.704a2.999 2.999 0 01-.366-.366C2 18.093 2 16.84 2 14.33V9.67z"
            fill="currentColor"
        />
    </svg>
);
