import React from 'react';

import { IconProps } from '../IconRoot';

export const Folder: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 6a3 3 0 013-3h5a2 2 0 011.755 1.041c.266.484.693.959 1.245.959h6.68c1.23 0 1.844 0 2.336.177a3 3 0 011.807 1.807C23 7.476 23 8.09 23 9.32v7.36c0 1.23 0 1.844-.177 2.336a3 3 0 01-1.807 1.807C20.524 21 19.91 21 18.68 21H5.32c-1.23 0-1.844 0-2.336-.177a3 3 0 01-1.807-1.807C1 18.524 1 17.91 1 16.68V6zm2.996 1.019C4.29 7 4.68 7 5.32 7h13.36c.639 0 1.03 0 1.324.019.25.015.327.037.335.04a1 1 0 01.602.602c-.003-.008.024.055.04.335C21 8.29 21 8.68 21 9.32v7.36c0 .639 0 1.03-.019 1.324a1.606 1.606 0 01-.04.335 1 1 0 01-.602.602c.008-.003-.055.024-.335.04-.295.018-.686.019-1.324.019H5.32c-.639 0-1.03 0-1.324-.019a1.608 1.608 0 01-.335-.04 1 1 0 01-.602-.602c.003.008-.024-.055-.04-.335A24.96 24.96 0 013 16.68V9.32c0-.639 0-1.03.019-1.324.016-.28.043-.343.04-.335a1 1 0 01.602-.602c-.008.003.055-.024.335-.04z"
            fill="currentColor"
        />
    </svg>
);
