import React from 'react';

import { IconProps } from '../IconRoot';

export const PictureFilled: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 4C4.58172 4 1 7.58172 1 12V24C1 28.4183 4.58172 32 9 32H27C31.4183 32 35 28.4183 35 24V12C35 7.58172 31.4183 4 27 4H9ZM29 10.7941C29 12.4022 27.6569 13.7059 26 13.7059C24.3432 13.7059 23 12.4022 23 10.7941C23 9.18599 24.3432 7.88235 26 7.88235C27.6569 7.88235 29 9.18599 29 10.7941ZM13.6965 14.9295C13.3188 14.5629 12.7215 14.5519 12.3305 14.9043L5.33053 21.2131C4.92027 21.5828 4.88744 22.2151 5.25718 22.6254C5.62692 23.0356 6.25924 23.0685 6.66949 22.6987L12.9747 17.0161L18.2931 22.1781C18.298 22.183 18.3029 22.1878 18.3079 22.1926C18.3105 22.1951 18.3131 22.1976 18.3157 22.2L21.8035 25.5853C22.1998 25.9699 22.8329 25.9605 23.2176 25.5642C23.6022 25.1678 23.5928 24.5347 23.1965 24.1501L20.4806 21.5141L23.5 18.9091L27.3468 22.2279C27.7649 22.5886 28.3964 22.5421 28.7572 22.1239C29.1179 21.7058 29.0714 21.0743 28.6532 20.7135L24.1532 16.8312C23.7779 16.5074 23.2221 16.5074 22.8468 16.8312L19.0398 20.1157L13.6965 14.9295Z"
            fill="currentColor"
        />
    </svg>
);
