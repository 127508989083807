import React from 'react';

import { IconProps } from '../IconRoot';

export const Message: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2.477 4.389C2 5.325 2 6.549 2 9v2.25c0 2.45 0 3.675.477 4.611a4.375 4.375 0 001.912 1.912c.637.325 1.41.428 2.611.462v1.997c0 1.114 1.346 1.672 2.134.884l2.607-2.607c.081-.081.148-.168.201-.259H15c2.45 0 3.675 0 4.611-.477a4.376 4.376 0 001.912-1.912c.477-.936.477-2.16.477-4.611V9c0-2.45 0-3.675-.477-4.611a4.375 4.375 0 00-1.912-1.912C18.675 2 17.451 2 15 2H9c-2.45 0-3.675 0-4.611.477a4.375 4.375 0 00-1.912 1.912z"
            fill="currentColor"
        />
    </svg>
);
