import React from 'react';

import { IconProps } from '../IconRoot';

export const SaluteCircle: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.005 1C6.215 1 1.5 5.715 1.5 11.505c0 5.79 4.715 10.506 10.505 10.506 5.79 0 10.506-4.715 10.506-10.505C22.511 5.715 17.796 1 12.006 1zm2.885 2.193h.175c.165 0 .34.044.494.12.745.374 1.37.747 1.875 1.108-.582-.01-1.316.055-1.645.176-.68.241-1.152.69-1.294 1.217-.12.428-.011.866.296 1.25.735.9 2.939 1.196 4.112.779.395-.143.724-.351.954-.614.395.723.691 1.502.867 2.314-.373.647-1.01 1.162-1.832 1.48-2.467.954-6.14.01-7.709-1.985-.69-.91-.932-1.974-.658-2.994.307-1.118 1.184-2.028 2.402-2.5a5.474 5.474 0 011.963-.35zm-2.588 17.26h-.396a8.94 8.94 0 01-2.357-.34l-.11-.032-.098-.033c-.033-.01-.066-.022-.11-.033l-.11-.033c-.022-.01-.043-.01-.098-.033l-.143-.055c-.055-.021-.099-.032-.153-.054l-.1-.044a8.953 8.953 0 01-5.361-6.382c-.044-.22-.044-.57.043-.351.933 2.16 3.28 4.397 6.668 5.22 1.063.263 2.215.35 3.224.427 1.381.11 2.357.154 2.401 1.01-1.03.449-2.149.7-3.3.734zm6.415-3.026c.131-2.401-2.961-1.71-3.685-1.623-9.069 1.173-10.78-2.818-11.043-5.198-.197-1.831.428-4.08 1.766-5.494.406-.438 1.042-.92 1.875-1.436a9.11 9.11 0 014.397-1.13c.055 0 .055.022.011.033-6.009 1.875-5.647 6.24-4.836 7.852 2.544 5.033 11.778 1.557 13.423 3.202.044.044.055.12.033.186a8.96 8.96 0 01-1.941 3.608z"
            fill="currentColor"
            fillOpacity={0.96}
        />
    </svg>
);
