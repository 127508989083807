import React from 'react';

import { IconProps } from '../IconRoot';

export const Network: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.794 4c.367-.009.719.148.956.432a8.96 8.96 0 012.25 5.96 8.975 8.975 0 01-2.25 5.942 1.2 1.2 0 11-1.8-1.574 6.582 6.582 0 001.65-4.368c0-1.689-.621-3.205-1.65-4.367a1.2 1.2 0 01.713-2.006c.042-.009.082-.013.125-.018h.006zM5.4 10.392c0-1.672.61-3.184 1.631-4.348.335-.366.41-.9.19-1.343a1.196 1.196 0 00-1.184-.663 1.2 1.2 0 00-.806.412A9.002 9.002 0 003 10.392a8.98 8.98 0 002.307 6.018 1.2 1.2 0 101.78-1.613A6.533 6.533 0 015.4 10.392zM12 7.393c-1.655 0-3 1.345-3 3 0 1.295.826 2.397 1.978 2.816v6.203a1.021 1.021 0 002.043 0v-6.203A3.001 3.001 0 0015 10.393c0-1.655-1.345-3-3-3z"
            fill="currentColor"
        />
    </svg>
);
