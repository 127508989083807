import React from 'react';

import { IconProps } from '../IconRoot';

export const Music: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.58172 0 0 3.58172 0 8V16C0 20.4183 3.58172 24 8 24H16C20.4183 24 24 20.4183 24 16V8C24 3.58172 20.4183 0 16 0H8ZM6.93317 5.83789V5.83785H18.6665V8.12109H18.6663V15.2399L18.6664 15.2564L18.6663 15.2729V15.5417H18.6503C18.5044 16.826 17.3853 17.825 16.0264 17.825C14.5684 17.825 13.3864 16.675 13.3864 15.2564C13.3864 13.8378 14.5684 12.6877 16.0264 12.6877C16.1255 12.6877 16.2234 12.6931 16.3197 12.7034V8.12109H9.27983V17.2541H9.26358C9.11765 18.5384 7.99857 19.5373 6.6397 19.5373C5.18166 19.5373 3.99969 18.3873 3.99969 16.9687C3.99969 15.55 5.18166 14.4 6.6397 14.4C6.73889 14.4 6.83681 14.4053 6.93317 14.4157V8.12109V5.83789Z"
            fill="currentColor"
        />
    </svg>
);
