import React from 'react';

import { IconProps } from '../IconRoot';

export const MusicMic: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.744 4.255c1.675 1.674 1.675 4.44 0 6.114-.242.243-.509.437-.8.631L14 5.056c.17-.291.388-.558.63-.8 1.675-1.675 4.44-1.675 6.114 0zm-7.733 3.733c.026.02.052.038.078.056.07.047.135.092.188.163l3.516 3.516c.073.072.146.17.219.266A4.312 4.312 0 0019 11.65L13.35 6a4.312 4.312 0 00-.34 1.988zM2.673 19.22a1.548 1.548 0 002.287 0 .544.544 0 01.228-.16l-.022-.023a.663.663 0 01-.026-.83l7.408-9.051A.36.36 0 0112.834 9c.104 0 .208.052.286.13l3.77 3.76c.077.078.13.182.103.286a.618.618 0 01-.156.285l-9.046 7.418a.667.667 0 01-.832-.052l-.104-.104a1.135 1.135 0 01-1.594-.142 2.71 2.71 0 01-3.44-.455c-1.095-1.165-1.095-3.08 0-4.246l2.506-2.666a1.798 1.798 0 000-2.434 1.548 1.548 0 00-2.287 0 .564.564 0 01-.851 0 .654.654 0 010-.906 2.715 2.715 0 013.99 0c1.095 1.165 1.095 3.08 0 4.246l-2.506 2.666a1.798 1.798 0 000 2.434z"
            fill="currentColor"
        />
    </svg>
);
