import React from 'react';

import { IconProps } from '../IconRoot';

export const PercentStroke: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            clipRule="evenodd"
            d="M12.3369 9C14.8222 9 16.8369 11.0147 16.8369 13.5C16.8369 15.9853 14.8222 18 12.3369 18C9.85163 18 7.83691 15.9853 7.83691 13.5C7.83691 11.0147 9.85163 9 12.3369 9ZM12.3369 11C10.9562 11 9.83691 12.1193 9.83691 13.5C9.83691 14.8807 10.9562 16 12.3369 16C13.7176 16 14.8369 14.8807 14.8369 13.5C14.8369 12.1193 13.7176 11 12.3369 11ZM23.3369 20C25.8222 20 27.8369 22.0147 27.8369 24.5C27.8369 26.9853 25.8222 29 23.3369 29C20.8516 29 18.8369 26.9853 18.8369 24.5C18.8369 22.0147 20.8516 20 23.3369 20ZM23.3369 22C21.9562 22 20.8369 23.1193 20.8369 24.5C20.8369 25.8807 21.9562 27 23.3369 27C24.7176 27 25.8369 25.8807 25.8369 24.5C25.8369 23.1193 24.7176 22 23.3369 22ZM9.54402 28.7071L27.544 10.7071C27.9345 10.3166 27.9345 9.68342 27.544 9.29289C27.1535 8.90237 26.5203 8.90237 26.1298 9.29289L8.12981 27.2929C7.73928 27.6834 7.73928 28.3166 8.12981 28.7071C8.52033 29.0976 9.1535 29.0976 9.54402 28.7071Z"
            stroke="currentColor"
            strokeWidth={2}
        />
    </svg>
);
