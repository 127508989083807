import React from 'react';

import { IconProps } from '../IconRoot';

export const VolumeUp: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.827 7.387l5.315-4.09c.94-.722 2.296-.043 2.296 1.116l-.001 14.15a1.407 1.407 0 01-2.274 1.137l-5.336-4.09H3.225A1.223 1.223 0 012 14.385V8.612c0-.678.547-1.225 1.225-1.225h2.602zm13.8-2.493c-.437-.35-1.07-.262-1.443.153-.35.437-.306 1.094.131 1.444 1.443 1.203 2.384 2.996 2.384 5.008 0 2.011-.94 3.827-2.384 5.007-.437.35-.48 1.007-.131 1.444.35.438 1.006.525 1.444.175a8.482 8.482 0 003.17-6.604c-.021-2.69-1.246-5.075-3.17-6.627zm-2.384 2.93a1.044 1.044 0 00-1.465.153c-.372.46-.285 1.116.175 1.487a2.63 2.63 0 01.962 2.035c0 .874-.415 1.64-1.071 2.12a.918.918 0 00-.153 1.313l.087.087c.35.415 1.006.503 1.421.175a4.697 4.697 0 001.794-3.695c0-1.488-.678-2.8-1.75-3.675z"
            fill="currentColor"
        />
    </svg>
);
