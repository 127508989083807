import React from 'react';

import { IconProps } from '../IconRoot';

export const PlusCircle: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9999 26C20.1795 26 25.9999 20.18 25.9999 13C25.9999 5.82072 20.1792 0 12.9999 0C5.82059 0 -0.00012207 5.82072 -0.00012207 13C-0.00012207 20.18 5.82026 26 12.9999 26ZM12.9999 2C19.0746 2 23.9999 6.92528 23.9999 13C23.9999 19.0754 19.075 24 12.9999 24C6.9248 24 1.99988 19.0754 1.99988 13C1.99988 6.92528 6.92516 2 12.9999 2ZM13.9999 12H18.9999C19.5522 12 19.9999 12.4477 19.9999 13C19.9999 13.5523 19.5522 14 18.9999 14H13.9999V19C13.9999 19.5523 13.5522 20 12.9999 20C12.4476 20 11.9999 19.5523 11.9999 19V14H6.99988C6.44759 14 5.99988 13.5523 5.99988 13C5.99988 12.4477 6.44759 12 6.99988 12H11.9999V7C11.9999 6.44772 12.4476 6 12.9999 6C13.5522 6 13.9999 6.44772 13.9999 7V12Z"
            fill="currentColor"
            fillOpacity="0.96"
        />
    </svg>
);
