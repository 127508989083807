import React from 'react';

import { IconProps } from '../IconRoot';

export const CallEnd: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#ic_24_call_end_svg__clip0)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.102 14.102a3.315 3.315 0 01-.43-.572 3.994 3.994 0 01-.337-.635c-.137-.336-.1-.876.114-1.62.203-.686.523-1.247.959-1.683.128-.128.26-.244.397-.348.138-.104.303-.21.498-.32.194-.108.34-.186.44-.234.1-.047.28-.13.54-.248.26-.119.419-.192.476-.22a9.098 9.098 0 011.832-.654c.98-.232 2.116-.348 3.409-.348 1.293 0 2.429.116 3.41.348a9.1 9.1 0 011.831.654c.057.028.216.101.476.22.26.118.44.201.54.248.1.048.246.126.44.235a3.866 3.866 0 01.895.668c.436.435.756.996.96 1.683.212.743.25 1.283.113 1.619-.066.17-.179.382-.338.636a3.315 3.315 0 01-.43.571.562.562 0 01-.17.128c-.113.057-.419.002-.916-.163-.142-.038-.34-.095-.596-.17a51.27 51.27 0 00-.7-.203c-.21-.06-.41-.113-.6-.16a12.358 12.358 0 01-.302-.053 2.81 2.81 0 01-.405-.1.654.654 0 01-.252-.152c-.095-.095-.146-.28-.153-.558-.007-.277.01-.554.05-.83.04-.278.062-.55.064-.818.002-.267-.04-.444-.124-.529a.68.68 0 00-.196-.124 2.214 2.214 0 00-.206-.085 3.65 3.65 0 00-.27-.071 5.64 5.64 0 01-.216-.053c-1.009-.29-1.977-.434-2.905-.434-.928 0-1.896.145-2.905.434a5.648 5.648 0 01-.216.053c-.13.03-.22.054-.27.07-.05.017-.119.046-.206.086a.68.68 0 00-.196.124c-.085.085-.126.262-.124.53.002.267.024.54.064.816.04.277.057.554.05.831-.007.277-.058.463-.153.558a.655.655 0 01-.252.152 2.81 2.81 0 01-.405.1l-.302.053c-.19.047-.39.1-.6.16-.21.06-.444.126-.7.202a24.1 24.1 0 01-.596.17c-.497.166-.803.22-.916.164a.561.561 0 01-.17-.128z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="ic_24_call_end_svg__clip0">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
