import React from 'react';

import { IconProps } from '../IconRoot';

export const Headphones: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3C6.477 3 2 7.477 2 13v3.667c0 .31 0 .465.034.592a1 1 0 00.707.707c.127.034.282.034.592.034.155 0 .233 0 .296-.017a.5.5 0 00.354-.354C4 17.566 4 17.49 4 17.333V13a8 8 0 1116 0v4.333c0 .155 0 .233.017.296a.5.5 0 00.354.354c.063.017.14.017.296.017.31 0 .465 0 .592-.034a1 1 0 00.707-.707c.034-.127.034-.282.034-.592V13c0-5.523-4.477-10-10-10zM5 15.333c0-.31 0-.465.034-.592a1 1 0 01.707-.707C5.868 14 6.023 14 6.333 14c.62 0 .93 0 1.185.068a2 2 0 011.414 1.414c.068.255.068.565.068 1.185v1.666c0 .62 0 .93-.068 1.185a2 2 0 01-1.414 1.414C7.263 21 6.953 21 6.333 21c-.31 0-.465 0-.592-.034a1 1 0 01-.707-.707C5 20.132 5 19.977 5 19.667v-4.334zm10.068.15c-.068.254-.068.564-.068 1.184v1.666c0 .62 0 .93.068 1.185a2 2 0 001.414 1.414c.255.068.565.068 1.185.068.31 0 .465 0 .592-.034a1 1 0 00.707-.707c.034-.127.034-.282.034-.592v-4.334c0-.31 0-.465-.034-.592a1 1 0 00-.707-.707C18.132 14 17.977 14 17.667 14c-.62 0-.93 0-1.185.068a2 2 0 00-1.414 1.414z"
            fill="currentColor"
        />
    </svg>
);
