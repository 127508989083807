import React from 'react';

import { IconProps } from '../IconRoot';

export const MicOff: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.79 2.386a1 1 0 00-1.58 1.228L9 9.772V11a2.996 2.996 0 003.279 2.987l1.353 1.74A4.973 4.973 0 0112 16c-2.47 0-4.52-1.8-4.93-4.15a.998.998 0 00-.98-.85c-.61 0-1.09.54-1 1.14.49 3 2.89 5.35 5.91 5.78V20c0 .55.45 1 1 1s1-.45 1-1v-2.08a6.972 6.972 0 001.905-.556l3.306 4.25a1 1 0 101.578-1.228l-14-18zm10.488 11.2l1.29 1.658a6.94 6.94 0 001.342-3.104c.1-.6-.39-1.14-1-1.14-.49 0-.9.36-.98.85a4.991 4.991 0 01-.652 1.735zm-7.202-9.26l5.816 7.477c.07-.255.108-.525.108-.803V5c0-1.66-1.34-3-3-3-1.428 0-2.62.992-2.924 2.326z"
            fill="currentColor"
        />
    </svg>
);
