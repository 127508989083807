import React from 'react';

import { IconProps } from '../IconRoot';

export const MagicWand: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M22.4 10.092l-1.304-.277-.652-1.242-.652 1.242-1.304.277.897 1.021-.136 1.409 1.195-.608 1.195.608-.163-1.409.924-1.021zm-5.487-2.817l1.603-.801 1.602.8-.217-1.877 1.195-1.381-1.738-.359L18.516 2l-.842 1.657-1.739.359 1.195 1.38-.217 1.879zM8.9 5.949l1.195-.607 1.195.607-.163-1.408.896-1.022-1.303-.276L10.068 2l-.625 1.243-1.304.276.896 1.022L8.9 5.949zm7.415 3.176l-.923-.939a1.912 1.912 0 00-2.744 0L2.57 18.404a1.99 1.99 0 000 2.79l.924.938c.76.774 1.983.774 2.744 0l10.077-10.245c.76-.746.76-2.016 0-2.762 0 0 .76.746 0 0zm-.923 1.85l-2.744 2.79c-.244.248-.652.248-.923 0l-.897-.94c-.244-.248-.244-.662 0-.938l2.744-2.79c.244-.248.652-.248.923 0l.924.94c.217.248.217.69-.027.938 0 0 .244-.248 0 0zm2.254 4.999l-.434-.829-.435.829-.87.166.599.69-.11.939.789-.414.787.414-.081-.911.597-.69-.842-.194z"
            fill="currentColor"
        />
    </svg>
);
