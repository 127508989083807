import React from 'react';

import { IconProps } from '../IconRoot';

export const VideoClip: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.247 15.382c-.55.335-1.247-.083-1.247-.758V9.376c0-.675.692-1.093 1.247-.758 1.446.871 2.88 1.743 4.337 2.62.555.341.555 1.189 0 1.523-1.446.872-2.886 1.744-4.337 2.621zm8.168-11.878C17.792 3.115 14.208 3 12.5 3c-1.707 0-5.292.115-6.915.504-1.548.373-2.73 1.087-3.144 2.793-.345 1.422-.445 4.194-.44 5.7-.005 1.507.095 4.284.44 5.706.414 1.707 1.596 2.42 3.144 2.793 1.623.389 5.208.504 6.915.504 1.708 0 5.292-.116 6.915-.504 1.548-.373 2.73-1.086 3.145-2.793.344-1.422.445-4.215.44-5.706.005-1.48-.096-4.278-.44-5.7-.414-1.706-1.597-2.42-3.145-2.793z"
            fill="currentColor"
        />
    </svg>
);
