import React from 'react';

import { IconProps } from '../IconRoot';

export const HeartStroke: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.2417 8.29386L12 9.17578L12.7583 8.29386C13.3296 7.62931 13.9595 6.99158 14.6357 6.52426C15.3101 6.05811 15.9746 5.7998 16.6342 5.7998C19.3278 5.7998 21 8.13178 21 10.0103C21 12.1722 19.74 14.3837 17.8108 16.5124C16.0799 18.4221 13.9221 20.1439 12 21.5612C10.0779 20.1439 7.92006 18.4221 6.18925 16.5124C4.26004 14.3837 3 12.1722 3 10.0103C3 8.13178 4.67225 5.7998 7.36585 5.7998C8.02538 5.7998 8.68992 6.05811 9.36434 6.52426C10.0405 6.99158 10.6704 7.62931 11.2417 8.29386Z"
            stroke="currentColor"
            strokeOpacity="0.96"
            strokeWidth="2"
        />
    </svg>
);
