import React from 'react';

import { IconProps } from '../IconRoot';

export const StarFill: React.FC<IconProps> = (props) => (
    <svg width="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.9998 17.5196L16.1498 20.0296C16.9098 20.4896 17.8398 19.8096 17.6398 18.9496L16.5398 14.2296L20.2098 11.0496C20.8798 10.4696 20.5198 9.36964 19.6398 9.29964L14.8098 8.88964L12.9198 4.42964C12.5798 3.61964 11.4198 3.61964 11.0798 4.42964L9.18983 8.87964L4.35983 9.28964C3.47983 9.35964 3.11983 10.4596 3.78983 11.0396L7.45983 14.2196L6.35983 18.9396C6.15983 19.7996 7.08983 20.4796 7.84983 20.0196L11.9998 17.5196Z"
            fill="currentColor"
        />
    </svg>
);
